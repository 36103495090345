import RMSApi, { Options } from '../request';

const request = new RMSApi();

export const getCurrentUser = () => {
  return request.get<I.User>('/user/current');
};

export const listSource = (
  params?: I.PageRequest<{
    status?: boolean;
    name?: string;
    uniq_name?: string;
  }>,
) => {
  return request.get<I.Page<I.Source>>('/source', {
    params,
  });
};

export const addSource = (params: Pick<I.Source, 'name' | 'uniq_name'>) => {
  return request.post('/source', {
    data: params,
  });
};

export const listChannel = (
  params?: I.PageRequest<{
    hide?: boolean;
    source_id?: I.ID;
    company_id?: I.ID;
    mode?: I.ChannelMode;
    channel_id?: I.ID[];
  }>,
) => {
  return request.get<I.Page<I.Channel>>('/channel', { params });
};

export const listChannelStatis = (start?: number, end?: number) => {
  const qs = new URLSearchParams();
  if (start) {
    qs.append('start', start.toString());
  }
  if (end) {
    qs.append('end', end.toString());
  }
  return request.get<I.ChannelStatistic[] | undefined>(
    `/customer/statistic?${qs.toString()}`,
  );
};

type ChannelAddParams = Exclude<
  I.Channel,
  'user' | 'source' | 'company' | 'id' | 'created_at' | 'updated_at'
> & { targets?: I.ID[] };

export const addChannel = (data: ChannelAddParams) => {
  return request.post('/channel', {
    data,
  });
};

export const updateChannel = (id: I.ID, data: ChannelAddParams) => {
  return request.put<Exclude<I.Channel, 'user' | 'company' | 'source'>>(
    `/channel/${id}`,
    { data },
  );
};

export const listAllSource = () => {
  return request.get<Exclude<I.Source, 'user' | 'channels'>[]>('/source/all');
};

export const listAllCompany = () => {
  return request.get<I.Company[]>('/company');
};

export const listCustomer = (
  params?: I.PageRequest<{
    push_status?: number;
    mobile?: string;
    name?: string;
    source_id?: I.ID;
    channel_id?: I.ID;
  }>,
) => {
  return request.get<I.Page<I.Customer>>('/customer', { params });
};

export const cleanCustomer = () => {
  return request.delete('/customer');
};

export const deleteCustomer = (id: I.ID) => {
  return request.delete(`/customer/${id}`);
};

export const getCode = () => {
  return request.post<number>('/login/code');
};

export const login = (data: {
  mobile: string;
  code: string;
  password: string;
  token?: number;
}) => {
  return request.post<I.User>('/login', { data });
};

export const logout = () => {
  return request.post('/logout');
};

export const updatePassword = (
  uid: I.ID,
  data: { password: string; password2: string; old_password: string },
) => {
  return request.put(`/user/${uid}/password`, { data });
};

export const isClose = (channel: string, token: string) => {
  return request.get<boolean>('/channel/closed', {
    params: { channel, token },
  });
};

export const push = (
  source_id: I.ID,
  data: {
    token: string;
    channel: string;
    name: string;
    mobile: string;
    remark: string;
    quota: number;
    extra: {
      [key: string]: boolean;
    };
  },
) => {
  return request.post(`/push/${source_id}`, { data });
};

export const listChannelPush = (
  params?: I.PageRequest<{
    status?: boolean;
    user_id?: I.ID;
    company_id?: I.ID[];
    channel_id?: I.ID[];
    mode?: I.ChannelMode;
  }>,
) => {
  return request.get<I.Page<I.ChannelPushItem>>(`/channel/push`, { params });
};

export const getChannnlPushDetail = (id: I.ID) => {
  return request.get<
    I.ChannelPushItem & {
      targets: { id: I.ID; target_id: I.ID; channel_push_target_id: I.ID }[];
    }
  >(`/channel/push/${id}`);
};

export const listAllChannel = () => {
  return request.get<(I.Channel & { edges: { source: I.Source } })[]>(
    '/channel/all',
  );
};

export const addChannelPush = (data: {
  channel_id: I.ID;
  company_id: I.ID;
  mode: I.ChannelMode;
  targets?: I.ID[];
  limit?: number;
  remark?: string;
}) => {
  return request.post('/channel/push', { data });
};

export const updateChannelPush = (
  id: I.ID,
  data: {
    mode: I.ChannelMode;
    targets?: I.ID[];
    limit?: number;
    remark?: string;
  },
) => {
  return request.put(`/channel/push/${id}`, { data });
};

export const listCompanyInfo = (companyId: I.ID) => {
  return request.get<I.CompanyInfo>(`/company/${companyId}/info`);
};

export const updateCompany = (
  companyId: I.ID,
  data: {
    name: string;
    api: string;
    token: string;
    status: boolean;
    remark: string;
  },
) => {
  return request.put(`/company/${companyId}`, { data });
};

export const addCompany = (data: {
  name: string;
  api: string;
  token: string;
  status: boolean;
  remark: string;
}) => {
  return request.post(`/company`, { data });
};

export const getCompany = (id: I.ID) => {
  return request.get<I.Company>(`/company/${id}`);
};

export const listCustomerInfo = (rmsId: I.ID[]) => {
  const qs = new URLSearchParams();
  rmsId.forEach((id) => {
    qs.append('rms_id', id.toString());
  });
  return request.get<{
    [rmsId: I.ID]: I.CustomerInfo;
  }>(`/customer/info?${qs.toString()}`);
};

export const crmProxy = (
  method: string,
  companyId: I.ID,
  path: string,
  options: Options,
) => {
  return request.request(method, `/crm/${companyId}/${path}`, options);
};

export const importAllCustomer = (file: File) => {
  return request.post<{ success: number; total: number }>(
    '/system/import_mobile_hash',
    {
      files: {
        file,
      },
    },
  );
};

export const getAllCustomerInfo = () => {
  return request.get<{ total: number }>('/system/all_customer_info');
};
