import {
  addCompany,
  getCompany,
  listAllCompany,
  updateCompany,
} from '@/apis/api';
import { getAnalysisURL, getMobileHashURL } from '@/apis/request';
import BoolStatus from '@/components/BoolStatus';
import useUser from '@/hooks/user';
import { PageHeader } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  List,
  MenuProps,
  Modal,
  Space,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import ImportMobile from './ImportMobile';

const AddOrUpdateCompanyModal = ({
  type,
  id,
  refresh,
}: { refresh: () => void } & (
  | {
      type: 'update';
      id: I.ID;
    }
  | { type: 'add'; id?: undefined }
)) => {
  const [open, setOpen] = useState<boolean>();

  const [form] = Form.useForm();

  const {
    data: company,
    loading: getLoading,
    refresh: refreshCompany,
  } = useRequest(
    () => {
      return getCompany(id as I.ID);
    },
    {
      ready: !!id && open,
      refreshDeps: [id],
      onSuccess: () => {
        form.resetFields();
      },
    },
  );

  useEffect(() => {
    if (open) {
      if (type === 'update') {
        refreshCompany();
      } else {
        form.resetFields();
      }
    }
  }, [open, form, refreshCompany, type]);

  const { runAsync: add, loading: addLoading } = useRequest(addCompany, {
    manual: true,
    onSuccess: () => {
      refresh();
      message.success('添加成功');
      setOpen(false);
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { runAsync: update, loading: onLoading } = useRequest(updateCompany, {
    manual: true,
    onSuccess: () => {
      refresh();
      message.success('修改成功');
      setOpen(false);
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const loading = getLoading || addLoading || onLoading;

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        if (type === 'add') {
          return add(values);
        } else {
          return update(id as I.ID, values);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Modal
        confirmLoading={loading}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
        title={type === 'add' ? '新增' : '修改'}
      >
        <Form
          form={form}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          initialValues={{
            ...company,
          }}
        >
          <Form.Item label="公司名称" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="API 地址" name="api" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="token" name="token" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="接收推送"
            name="status"
            rules={[{ required: true }]}
            valuePropName="checked"
          >
            <Checkbox>开启</Checkbox>
          </Form.Item>
          <Form.Item label="描述" name="remark">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        {type === 'add' ? '新增' : '修改'}
      </Button>
    </>
  );
};

const MobileHashDownload = () => {
  const { user, loading } = useUser();

  return (
    <Button
      disabled={user.role !== 9}
      type="link"
      href={getMobileHashURL()}
      loading={loading}
      target="_blank"
    >
      排重包
    </Button>
  );
};

const AnaslysisDownload = () => {
  const items: MenuProps['items'] = [
    {
      label: (
        <a target="_blank" href={getAnalysisURL(true)} rel="noreferrer">
          上月
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a href={getAnalysisURL()} target="_blank" rel="noreferrer">
          本月
        </a>
      ),
      key: '1',
    },
  ];
  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button type="link" onClick={(e) => e.preventDefault()}>
        <Space>
          月报表
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default function CompanyIndex() {
  const {
    data,
    loading: companyLoading,
    refresh,
  } = useRequest(() => {
    return listAllCompany();
  });

  const loading = companyLoading;

  return (
    <PageHeader
      title="公司管理"
      extra={[
        <AddOrUpdateCompanyModal
          refresh={() => refresh()}
          key="add"
          type="add"
        />,
        <ImportMobile key="import-mobile" />,
        <MobileHashDownload key="download" />,
        <AnaslysisDownload key="analysis" />,
      ]}
    >
      <List
        loading={loading}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            title={item.name}
            actions={[
              <AddOrUpdateCompanyModal
                type="update"
                id={item.id}
                refresh={refresh}
              />,
            ]}
          >
            <List.Item.Meta
              title={
                <Space>
                  <span>{item.name}</span>
                  <BoolStatus status={item.status} />
                </Space>
              }
              description={
                <Space>
                  <span>{item.api}</span>
                  <span>{item.token}</span>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </PageHeader>
  );
}
