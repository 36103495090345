import { Button, Modal, Space, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { getAllCustomerInfo, importAllCustomer } from '@/apis/api';
import { useRequest } from 'ahooks';

export default function ImportMobile() {
  const [open, setOpen] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const { data: allCustomerInfo, run } = useRequest(getAllCustomerInfo, {
    manual: true,
  });

  useEffect(() => {
    if (open) {
      run();
    }
  }, [open, run]);

  const handleUpload = () => {
    setUploading(true);
    return importAllCustomer(fileList[0] as RcFile)
      .then((res) => {
        setFileList([]);
        message.success(`一共 ${res.total} 条数据，成功导入 ${res.success} 条`);
        setOpen(false);
      })
      .catch((e) => {
        message.error(e.message);
        throw e;
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const props: UploadProps = {
    accept: '.xlsx',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };
  return (
    <>
      <Modal
        title="导入手机号"
        open={open}
        closable={!uploading}
        onCancel={() => setOpen(false)}
        onOk={handleUpload}
      >
        <Space direction="vertical">
          <Space>
            <span>
              <Statistic
                value={allCustomerInfo?.total || 0}
                valueRender={(v) => {
                  return <span>当前一共存在 {v} 个手机号</span>;
                }}
              />
            </span>
          </Space>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>选择文件</Button>
          </Upload>
        </Space>
      </Modal>
      <Button loading={uploading} onClick={() => setOpen(true)}>
        导入手机号
      </Button>
    </>
  );
}
